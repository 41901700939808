const frontPage = () => {

    const videos = document.querySelectorAll('.copy-button-group');
    videos.forEach(function (video) {
        const copyButton = video.querySelector('.btn');
        const shortcodeInput = video.querySelector('input');
        copyButton.addEventListener('click', function () {
            if (navigator.clipboard == undefined) {
                alert("Your browser does not support the clipboard API. Please copy the shortcode manually.");
                return;
            }
            navigator.clipboard.writeText(shortcodeInput.value).then(function () {
                // Success feedback here. For example:
                console.log('Copying to clipboard was successful!');
            }, function (err) {
                // Error feedback here. For example:
                console.error('Could not copy text: ', err);
            });
        });
    });


    // const slackNameField = document.getElementById("slack_name");
    // const savedSlackName = localStorage.getItem("slack_name");
    // if (savedSlackName) {
    //     slackNameField.value = savedSlackName;
    // }
    //
    // slackNameField.addEventListener("change", function () {
    //     const slackName = slackNameField.value;
    //     localStorage.setItem("slack_name", slackName);
    // });


    const processedCountEl = document.getElementById('processed_count');
    const processingCountEl = document.getElementById('processing_count');
    const queStatsEl = document.getElementById('queue_status_group');
    const processedContainerEl = document.getElementById('processed-container');
    //const processingContainerEl = document.getElementById('processing_group');

    const updateStatus = (vidID, status) => {
        let vidStatusEl = document.getElementById(`status_label_video_${vidID}`);
        if (vidStatusEl) {
            vidStatusEl.innerText = status;
        }
    }
    const updateLastLogTime = (vidID, last_log_time_words) => {
        let vidThumbnailEl = document.getElementById(`last_log_time_in_words_video${vidID}`);
        console.log(`last_log_time_in_words_video_${vidID}`, vidThumbnailEl, last_log_time_words);

        if (vidThumbnailEl) {
            vidThumbnailEl.innerText = last_log_time_words;
        }
    }


    const updateThumbnail = (vidID, thumbnail_url) => {
        let vidThumbnailEl = document.getElementById(`p_thumb_video_${vidID}`);
        console.log(`p_thumb_video_${vidID}`, vidThumbnailEl, thumbnail_url);

        if (vidThumbnailEl) {
            vidThumbnailEl.src = thumbnail_url;
        }
    }
    window.fpChannel = App.cable.subscriptions.create({channel: "FrontPageChannel"}, {
        connected() {
            console.log("Connected to FrontPage channel:", this);
        },
        disconnected() {
            console.log("Disconnected from FrontPage channel:", this);
        },
        received(data) {
            window.last_message = data;
            const log_socket_messages = document.querySelector('#log_socket_messages');
            if (log_socket_messages?.checked) {
                if (data.type != "system_status") console.log("Socket Message:", data.type, data);
            }
            switch (data.type) {
                case "system_status":
                    if (processedCountEl) {
                        processedCountEl.innerText = data.processed_count;
                    }
                    if (processingCountEl) {
                        processingCountEl.innerText = data.processing_count;
                    }
                    if (queStatsEl) {
                        queStatsEl.innerHTML = data.que_stats;
                    }
                    break;
                case "video_log":
                    let tempDiv = document.createElement("div");
                    tempDiv.innerHTML = data.log;
                    let newElement = tempDiv.firstChild;

                    let logEl = document.getElementById('log_video_' + data.id);
                    if (logEl == null) {
                        return;
                    }
                    logEl.prepend(newElement);
                    while (logEl.childNodes.length > 5) {
                        logEl.lastChild.remove();
                    }

                    updateStatus(data.id, data.status);
                    updateThumbnail(data.id, data.thumbnail_url);
                    updateLastLogTime(data.id, data.last_log);
                    //card_status.innerText = data.status;
                    break;
                case "video_processing_start":
                    console.log("video_processing_start", data);
                    let processingContainerEl = document.getElementById('processing_group');
                    if(processingContainerEl == null){
                        console.log("processingContainerEl is null");
                        return;
                    }
                    let card_id = "status_video_" + data.id;
                    let statusCard = document.getElementById(card_id);
                    if (statusCard == null) {
                        console.log("statusCard is null, adding");
                        processingContainerEl.insertAdjacentHTML('afterbegin', data.message);
                    }else{
                        console.log("statusCard is not null");
                    }

                    break;
                case "video_processing_end":
                    window.end_message = data;
                    let card_id_end = "status_video_" + data.id;
                    let card_status = document.getElementById(card_id_end);
                    let hide = true;
                    if (card_status != null && hide) {
                        card_status.firstElementChild.classList.add("border-success");
                        setTimeout(() => {
                            card_status.firstElementChild.classList.remove("show");
                            setTimeout(() => {
                                card_status.remove();
                                // processedContainerEl.firstElementChild.insertAdjacentHTML('afterbegin', data.message.card_html);
                                // let grid = document.getElementById('grid');
                                // window.imagesLoaded(grid, function () {
                                //     window.updateGrid();
                                // });
                                //setTimeout(() => { window.updateGrid(); }, 1000);
                            }, 20);
                        }, 30);

                    }
                    break;
            }

        }
    });

}

export default frontPage;

